import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	*{
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		outline: 0px;
		font-family: "Poppins-Regular";
		/* font-weight: 400; */
	}

	body{
		font-family: "Poppins-Regular";
		background: ${(props) => props.theme.colors.white};
		transition: all 0.3s ease;
	}

	a{
		cursor: pointer;
	}

	.container-icone-blog {
		position: relative;
		margin-left: 30px;
		&.dark {
		&:before {
			background: ${({ theme }) => theme.colors.tertiary};
		}
		}
		&:before {
		content: "";
		display: block;
		width: 54px;
		height: 54px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-mask: url("/img/icone/icone-blog.svg");
		-webkit-mask-size: contain;
		background-color: ${(props) => props.theme.colors.black};
		}
	}

	h2{
		&.sub-title{
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 150%;
			color: #000;
		}
	}

	/* .icone-seta-dir {
      position: relative;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.tertiary};
      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-mask: url("/img/icone/seta-dir-card.svg");
        -webkit-mask-size: contain;
        background-color: #000;
      }
    } */

	.icone-seta-baixo{
		position: relative;
		height: 50px;
		&:before {
			content: "";
			display: block;
			width: 35px;
			height: 50px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-mask: url("/img/icone/seta-baixo.svg");
			-webkit-mask-size: contain;
			background-color: ${(props) => props.theme.colors.tertiary};
		}
	}

	.rotate-y{
		transform: rotateY(180deg);
	}
	
	.sistema{
		background-color: #fff;
		position: absolute;
		top: 99px;
		left: 112px;
		width: calc(100% - 112px);
		height: calc(100vh - 99px);
		overflow: scroll;
		padding-left: 47px;
		padding-right: 42px;
		&.login{
			background-color: transparent;
			position: static;
			width: 100%;
			height: 100%;
			padding: 0px;
		}
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(89, 92, 118, 0.5);
		}

		&::-webkit-scrollbar-track {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
	/* .MuiInputBase-formControl{
		height: 45px
	}

	.MuiInputLabel-formControl{
		top: -4px;
	} */

	input{
		&[type=file]{
			display: none;
		}
	}

	.fix-body{
		overflow: hidden;
	}

	.container-doble-form{
		width: 314px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0px 16px;
		.container-form{

		}
	}

	.container-form{
		margin-bottom: 14px;
		&.file{
			label{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img{
					display: block;
					margin-bottom: 41px;
				}
				&.file{
					font-size: 16px;
					line-height: 150%;
					letter-spacing: 0.02em;
					color: rgb(16 58 81 / 0.7);		

				}
			}

			input{
				&[type=file]{
					display: none;
				}
			}
		}
		&:last-child{
			margin-bottom: 19px;
		}

		&.cep{
			display: grid;
			grid-template-columns: 348px 1fr;
			margin-bottom: 46px;
			label{
				grid-column: span 2;
			}
			input{
				margin-bottom: 12px;
			}
		}

		label{
			color: ${(props) => props.theme?.colors?.tertiary}};
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.01em;
			margin-bottom: 8px;
		}

		input{
			background: #FFFFFF;
			border: 1px solid rgb(16 58 81 / 0.2);
			box-sizing: border-box;
			border-radius: 4px;
			height: 52px;
			width: 100%;
			padding-left: 29px;

			font-size: 16px;
			line-height: 19px;
		}

		input, textarea{
			&::placeholder{
				color: ${(props) => props.theme.colors.tertiary};
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.01em;
			}
		}

		textarea{
			background: #FFFFFF;
			border: 1px solid rgb(16 58 81 / 0.2);
			box-sizing: border-box;
			border-radius: 4px;
			height: 131px;
			width: 100%;
			padding-left: 29px;
			padding-top: 17px;
			font-size: 16px;
			line-height: 19px;
		}

		button{
			color: ${(props) => props.theme.colors.tertiary};
			font-size: 14px;
			line-height: 16px;
			text-decoration-line: underline;
			background-color: transparent;
		}
	}

	.container-form-sistema{
		margin-bottom: 14px;
		position: relative;
		height: 58px;
		margin-bottom: 22px;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		position: relative;
		&.textarea{
			label{
				top: 25px;
				left: 30px;
			}
		}
		&.border{
			border: 1px solid #E8E8E8;
		}
		
		&.categoria  {
			input {
				padding-left: 49px;
			}

			label{
				left: 49px;
			}

			position: relative;
			&:before{
				content: '';
				display: block;
				width: 20px;
    			height: 22px;
				position: absolute;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
				background-color: #4ACCA5;
				z-index: 20;
				-webkit-mask: url("/img/adm/categoria.svg");
				-webkit-mask-size: contain;
			}
		}

		&.user {
			input {
				padding-left: 49px;
			}

			label{
				left: 49px;
			}

			position: relative;
			&:before{
				content: '';
				display: block;
				width: 20px;
    			height: 22px;
				position: absolute;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
				background-color: #4ACCA5;
				z-index: 20;
				-webkit-mask: url("/img/adm/user.svg");
				-webkit-mask-size: contain;
			}
		}

		&.lupa {
			input {
				padding-left: 49px;
			}

			label{
				left: 49px;
			}

			position: relative;
			&:before{
				content: '';
				display: block;
				width: 15px;
				height: 15px;
				position: absolute;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
				background-color: #4ACCA5;
				z-index: 20;
				-webkit-mask: url("/img/adm/lupa.svg");
				-webkit-mask-size: contain;
			}
		}

		&.olho{
			.revelar-senha{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 19px;
				width: 21px;
				height: 16px;
				cursor: pointer;
				&:before{
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					right: 0px;
					transform: translateY(-50%);
					-webkit-mask: url("/img/adm/olho.svg");
					background-color: #4ACCA5;
					z-index: 20;
					-webkit-mask-size: contain;
				}
			}
		}

		&.file{
			label{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img{
					display: block;
					margin-bottom: 41px;
				}
				&.file{
					font-size: 16px;
					line-height: 150%;
					letter-spacing: 0.02em;
					color: rgb(16 58 81 / 0.7);		

				}
			}

			
		}
		
		&:last-child{
			margin-bottom: 0px;
		}
		

		&.cep{
			display: grid;
			grid-template-columns: 348px 1fr;
			margin-bottom: 46px;
			label{
				grid-column: span 2;
			}
			input{
				margin-bottom: 12px;
			}
		}

		span{
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 140%;
			color: #585858;
			display: block;
			position: absolute;
			bottom: -20px;
		}

		label{
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 140%;
			color: #585858;
			position: absolute;
			top: 50%;
			left: 23px;
			pointer-events: none;
			transform: translateY(-50%);
			z-index: 0;
			transition: all .2s;
		}

		input, textarea{
			background: transparent;
			border: 1px solid #E8E8E8;
			box-sizing: border-box;
			/* border-radius: 4px; */
			height: 56px;
			width: 100% !important;
			padding-left: 23px;

			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			color: ${(props) => props.theme.colors.black};
			transition: all .5s;

			&:hover{
				border: 1px solid #4ACCA5;
				transition: all .5s;
			}

			&:focus ~ label,
			&:valid ~ label{
				top: 10px;
				font-size: 10px;
				transition: all .2s;
			}
		}

		input:focus{
			border-color: #4ACCA5;
		}
		
		input, textarea{
			&::placeholder{
				color: ${(props) => props.theme.colors.white};
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.01em;
			}
		}

		textarea{
			background: #FFFFFF;
			border: 1px solid rgb(16 58 81 / 0.2);
			box-sizing: border-box;
			border-radius: 4px;
			height: 131px;
			width: 100%;
			padding-left: 29px;
			padding-top: 17px;
			font-size: 16px;
			line-height: 19px;
		}
	}

	.scroll-y{
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(89,92,118,0.5);
			
		}

		&::-webkit-scrollbar-track {
			
			background-color: rgba(255,255,255, 0.1);
		}
	}

	#root{
		margin-left: auto;
		margin-right: auto;
		max-width: 1903px;
		overflow: hidden;
		position: relative;
	}

	a,button{
		text-decoration: none;
		outline: none;
		border: none;
		cursor: pointer;
	}

	.container{
		width: 1215px;
		margin-left: auto;
		margin-right: auto;
	}


	li{
		list-style: none;
	}

	html{
		
	}

	body{
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		/* font-family: Montserrat; */
	}

	@media (max-width: 1366px) {
		.container {
			width: 90%;
		}
	}

	.MuiInputLabel-root{
		color: ${({ theme }) => theme.colors.black} !important;
	}

	.MuiInput-root{
		&::after{
			border-bottom: 1px solid ${({ theme }) => theme.colors.black} !important;
		}
		&:before{
			border-bottom: 1px solid ${({ theme }) => theme.colors.black} !important;
		}
		.MuiInput-input{
			color: ${({ theme }) => theme.colors.black};
			font-weight: bold;
		}

		.MuiInput-colorSecondary{
			color: ${({ theme }) => theme.colors.black} !important;
		}
	}
`;
