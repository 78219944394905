import React from "react";
import dynamic from "next/dynamic";
import { ThemeContextProvider } from "../contexts/ThemeContext";
import { UseSiteProvider } from "../hooks/useSite";

const Header = dynamic(() => import("../components/header"));
const Footer = dynamic(() => import("../components/footer"));
const Orcamento = dynamic(() => import("../components/Orcamento"), {
  ssr: true,
});
const Modal = dynamic(() => import("../components/Menu"));

import GlobalStyle from "../styles";

interface ProvidersProps {
  children?: React.ReactChild | React.ReactChild[];
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <UseSiteProvider>
      <ThemeContextProvider>
        <GlobalStyle />
        <Header />
        <>{children}</>
        <Footer />
        <Orcamento />
        <Modal />
      </ThemeContextProvider>
    </UseSiteProvider>
  );
}
