import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { lightColors, darkColorss, fonts, styleGuide } from "../styles/theme";
import { light, dark, Theme } from "cms-caravela";

interface SCThemeProviderProps {
  children?: React.ReactChild | React.ReactChild[];
}
interface ThemeProviderProps {
  currentTheme: Theme;
  lightTheme: () => void;
  darkTheme: () => void;
}

const ThemeContext = React.createContext<ThemeProviderProps>(
  {} as ThemeProviderProps
);

const ThemeContextProvider = ({ children }: SCThemeProviderProps) => {
  light.colors = { ...light.colors, ...lightColors };
  light.fonts = { ...fonts };
  light.styledGuide = { ...styleGuide };

  const [currentTheme, setCurrentTheme] = useState<Theme>(light);

  function lightTheme() {
    light.colors = { ...light.colors, ...lightColors };
    light.isDark = false;
    setCurrentTheme(light);
  }

  function darkTheme() {
    dark.colors = { ...dark.colors, ...darkColorss };
    dark.styledGuide = { ...styleGuide };
    dark.isDark = true;
    setCurrentTheme(dark);
  }

  useEffect(() => {
    light.colors = { ...light.colors, ...lightColors };
    setCurrentTheme(light);
  }, []);

  return (
    <ThemeContext.Provider value={{ lightTheme, darkTheme, currentTheme }}>
      <SCThemeProvider theme={currentTheme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  const context = useContext(ThemeContext);
  return context;
}

export { ThemeContext, ThemeContextProvider };
