// export const lightColors = {
//   white: "#FFFFFF",
//   black: "#000",

//   primary: "#696969",
//   primaryBright: "#74A798",
//   primaryDark: "#1d343e",

//   secondary: "#333333",
//   secondarybright: "#333333",
//   secondarydark: "#808080",

//   tertiary: "#00FFB2",

//   contrast: "#fff",
// };

// 100%
//
export const lightColors = {
  white: "#FFFFFF",
  black: "#000",

  primary: "#000",
  primaryBright: "#000",
  primaryDark: "#1d343e",

  secondary: "#333333",
  secondarybright: "#333333",
  secondarydark: "#808080",

  tertiary: "#00FFB2",

  contrast: "#fff",
};

// theme dark
export const darkColorss = {
  white: "#02161E",
  black: "#fff",

  primary: "#fff",
  primaryBright: "#74A798",
  primaryDark: "#fff",

  secondary: "#333333",
  secondarybright: "#fff",
  secondarydark: "#808080",

  tertiary: "#00FFB2",
  contrast: "#fff",
};

export const fonts = {
  xl: "4rem",
  lg: "2.625rem",
  mdl: "1.5rem",
  md: "1rem",
  sm: "0.938rem",
  xs: "0.875rem",
  xss: "0.75rem",
};

export const styleGuide = {
  h1: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: fonts.xl,
  },
  h2: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: fonts.lg,
  },
  h3: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: fonts.mdl,
  },
  h4: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "170%",
    letterSpacing: "0.02em",
    fontSize: "16x",
  },
  h5: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: "16x",
  },
  h6: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: "16x",
  },
  p: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "170%",
    letterSpacing: "0.02em",
    fontSize: fonts.md,
  },

  span: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.02em",
    fontSize: "16x",
  },
};
